import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";

import * as serviceWorker from './serviceWorker';
import { store } from "./store.js";
import { router } from "./router.js";

import 'bootstrap/dist/css/bootstrap.min.css';

//import 'stylesheets/custom.scss'
import "assets/scss/paper-dashboard.scss";

ReactDOM.render(
  <Provider store={store}>
    {router}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
